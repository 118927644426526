<template>
  <v-card>
    <v-toolbar class="mb-2" color="primary" dark flat>
      <v-icon class="mr-5">mdi-email-newsletter</v-icon>
      <v-toolbar-title>Email Templates</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="{ title, code } of templates" :key="title">
          <v-expansion-panel-header> {{ title }} </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    :rules="emailRule"
                    label="Email"
                    append-icon="mdi-send-circle"
                    @click:append="() => submit(code)"
                    required
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4" v-if="needDateField(code)">
                  <date-picker
                    v-model="date"
                    label="Date"
                    @clear="date = null"
                    :rules="[(v) => !!v || 'Date is required']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import {
  notifyConfirmation,
  notifyError,
  notifySuccess
} from '../../helpers/notifications';
import DatePicker from '../../components/appComponents/DatePicker.vue';
export default {
  components: { DatePicker },
  name: 'email-templates',
  data: () => ({
    templates: TEMPLATES,
    email: null,
    emailRule: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    overlay: false,
    valid: false,
    date: null
  }),
  methods: {
    ...mapActions('entry', ['sendEmail']),
    needDateField(code) {
      return ['weekly-recaptures', 'daily-record-edits'].includes(code);
    },
    async submit(code) {
      try {
        const [form] = this.$refs.form;
        const valid = form.validate();
        if (valid) {
          const template = this.templates.find(
            ({ code: templateCode }) => templateCode === code
          );
          notifyConfirmation(
            `You're about to send an email to ${this.email} using the ${template.title} template`,
            'Are you sure to proceed?',
            async () => {
              this.overlay = true;

              try {
                const payload = {
                  email: this.email,
                  type: code
                };

                if (this.needDateField(code)) {
                  payload.date = this.date;
                }

                const response = await this.sendEmail(payload);

                const { error, message } = response?.data || {};

                if (error) {
                  notifyError(message);
                } else {
                  notifySuccess(message);
                }
              } catch (error) {
                notifyError(
                  'An unexpected error has ocurred, try again or contact technical support'
                );
              }

              this.overlay = false;
            },
            () => {},
            'Send'
          );
        }
      } catch (error) {
        notifyError(
          'An unexpected error has ocurred, try again or contact technical support'
        );
      }
    }
  }
};

const TEMPLATES = [
  {
    title: 'Forgot Password',
    code: 'forgot-password'
  },
  {
    title: 'Join TBF',
    code: 'join-tbf'
  },
  {
    title: 'Renew Support',
    code: 'renew-support'
  },
  {
    title: 'Membership Expired',
    code: 'membership-expired'
  },
  {
    title: 'New User Registration',
    code: 'user-registration'
  },
  {
    title: 'User Registered by Facebook',
    code: 'registered-by-facebook'
  },
  {
    title: 'User Registered by Someone Else',
    code: 'registered-by-someone-else'
  },
  {
    title: 'Milestones Update',
    code: 'milestones-update'
  },
  {
    title: 'User Weekly Activity',
    code: 'user-weekly-activity'
  },
  {
    title: 'Email Change Notification',
    code: 'email-change-notification'
  },
  {
    title: 'Password Change Notification',
    code: 'password-change-notification'
  },
  {
    title: 'Draft Tags Reminder',
    code: 'draft-tags-reminder'
  },
  {
    title: 'Draft Release Reminder',
    code: 'draft-release-reminder'
  },
  {
    title: 'Weekly roundup for recaptures',
    code: 'weekly-recaptures'
  },
  {
    title: 'Daily roundup for record edits',
    code: 'daily-record-edits'
  },
  {
    title: 'Bi-Annual Reminder',
    code: 'biannual-reminder'
  }
];
</script>

<style>
.panel-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
